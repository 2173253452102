import * as React from 'react';

import NotFound from '../assets/images/not-found.inline.svg';
import { CtaButton } from '../components/shared/cta-button/cta-button.component';
import { CustomHeader } from '../components/shared/custom-header/custom-header.component';
import * as styles from './404.module.css';

const NotFoundPage: React.FC = () => (
    <>
        <CustomHeader transparent={false} dark={true} backgroundColor='var(--primary-blue)'/>
        <div className={`d-flex flex-column flex-sm-row justify-content-center align-items-center ${styles.root}`}>
            <div className='col-0 col-sm-1'></div>
            <div className='col-12 col-sm-5 d-flex flex-column'>
                <p>
                    <span className={styles.bigTxt}>NOT</span>
                    <br />
                    <span className={styles.bigTxt}>FOUND</span>
                    <br />
                    <span className={styles.smallTxt}>La page que vous recherchez semble introuvable.</span>
                </p>
                <CtaButton className='mt-2' link='/'>Accueil</CtaButton>
            </div>
            <NotFound className='col-11 col-sm-5 mt-4 mt-sm-0' />
            <div className='col-0 col-sm-1'></div>
        </div>
    </>
);

export default NotFoundPage;

export { Head } from '../components/head/head.component';
